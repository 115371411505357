import React from "react";

export default ({ color }) => (
  <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M71.7412 60.4138H1.25868C0.56361 60.4138 0 59.8502 0 59.1551V13.8449C0 13.1498 0.56361 12.5862 1.25868 12.5862H71.7413C72.4364 12.5862 73 13.1498 73 13.8449V59.1551C72.9999 59.8503 72.4362 60.4138 71.7412 60.4138Z" fill="#41479B"/>
    <path d="M37.0117 18.0626L37.5165 19.5758L39.1115 19.5882C39.3181 19.5898 39.4036 19.8533 39.2375 19.976L37.9543 20.9236L38.4354 22.4443C38.4977 22.6411 38.2736 22.8042 38.1055 22.684L36.8079 21.7564L35.5103 22.684C35.3423 22.8041 35.118 22.6412 35.1803 22.4443L35.6614 20.9236L34.3782 19.976C34.2121 19.8533 34.2976 19.5898 34.5042 19.5882L36.0992 19.5758L36.604 18.0626C36.6691 17.8667 36.9464 17.8667 37.0117 18.0626Z" fill="#FFE15A"/>
    <path d="M37.0117 50.5507L37.5165 52.0639L39.1115 52.0763C39.3181 52.0779 39.4036 52.3414 39.2375 52.4641L37.9543 53.4117L38.4354 54.9324C38.4977 55.1292 38.2736 55.2923 38.1055 55.1721L36.8079 54.2445L35.5103 55.1721C35.3423 55.2922 35.118 55.1293 35.1803 54.9324L35.6614 53.4117L34.3782 52.4641C34.2121 52.3414 34.2976 52.0779 34.5042 52.0763L36.0992 52.0639L36.604 50.5507C36.6691 50.3548 36.9464 50.3548 37.0117 50.5507Z" fill="#FFE15A"/>
    <path d="M53.2558 34.3067L53.7605 35.8198L55.3555 35.8322C55.5621 35.8338 55.6476 36.0973 55.4815 36.2201L54.1983 37.1676L54.6794 38.6884C54.7417 38.8851 54.5176 39.0482 54.3495 38.928L53.0519 38.0004L51.7543 38.928C51.5863 39.0481 51.362 38.8853 51.4243 38.6884L51.9054 37.1676L50.6222 36.2201C50.4561 36.0973 50.5416 35.8338 50.7482 35.8322L52.3433 35.8198L52.848 34.3067C52.9131 34.1108 53.1905 34.1108 53.2558 34.3067Z" fill="#FFE15A"/>
    <path d="M20.7676 34.3067L21.2723 35.8198L22.8673 35.8322C23.0739 35.8338 23.1595 36.0973 22.9934 36.2201L21.7102 37.1676L22.1912 38.6884C22.2535 38.8851 22.0294 39.0482 21.8613 38.928L20.5637 38.0004L19.2661 38.928C19.0982 39.0481 18.8739 38.8853 18.9362 38.6884L19.4172 37.1676L18.134 36.2201C17.9679 36.0973 18.0535 35.8338 18.2601 35.8322L19.8551 35.8198L20.3598 34.3067C20.4251 34.1108 20.7024 34.1108 20.7676 34.3067Z" fill="#FFE15A"/>
    <path d="M23.2063 25.8239L23.711 27.337L25.3061 27.3495C25.5127 27.351 25.5982 27.6145 25.4321 27.7373L24.1489 28.6848L24.63 30.2056C24.6923 30.4023 24.4681 30.5654 24.3 30.4452L23.0024 29.5176L21.7048 30.4452C21.5369 30.5653 21.3126 30.4025 21.3749 30.2056L21.856 28.6848L20.5728 27.7373C20.4067 27.6145 20.4922 27.351 20.6988 27.3495L22.2938 27.337L22.7985 25.8239C22.8638 25.628 23.1409 25.628 23.2063 25.8239Z" fill="#FFE15A"/>
    <path d="M51.3417 42.0679L51.8464 43.5811L53.4414 43.5935C53.648 43.595 53.7336 43.8585 53.5675 43.9813L52.2843 44.9289L52.7653 46.4496C52.8276 46.6463 52.6035 46.8095 52.4354 46.6893L51.1378 45.7617L49.8402 46.6893C49.6722 46.8093 49.448 46.6465 49.5103 46.4496L49.9913 44.9289L48.7081 43.9813C48.542 43.8585 48.6276 43.595 48.8342 43.5935L50.4292 43.5811L50.9339 42.0679C50.9992 41.872 51.2764 41.872 51.3417 42.0679Z" fill="#FFE15A"/>
    <path d="M45.396 19.8781L45.9007 21.3912L47.4958 21.4036C47.7024 21.4052 47.7879 21.6687 47.6218 21.7915L46.3386 22.739L46.8197 24.2598C46.882 24.4565 46.6578 24.6196 46.4897 24.4994L45.1921 23.5718L43.8945 24.4994C43.7266 24.6195 43.5023 24.4567 43.5646 24.2598L44.0457 22.739L42.7625 21.7915C42.5964 21.6687 42.6819 21.4052 42.8885 21.4036L44.4835 21.3912L44.9882 19.8781C45.0534 19.6822 45.3304 19.6822 45.396 19.8781Z" fill="#FFE15A"/>
    <path d="M29.1519 48.0137L29.6566 49.5269L31.2516 49.5393C31.4582 49.5408 31.5438 49.8043 31.3777 49.9271L30.0945 50.8747L30.5755 52.3954C30.6378 52.5921 30.4137 52.7553 30.2456 52.6351L28.948 51.7075L27.6504 52.6351C27.4824 52.7551 27.2582 52.5923 27.3205 52.3954L27.8015 50.8747L26.5183 49.9271C26.3522 49.8043 26.4378 49.5408 26.6444 49.5393L28.2394 49.5269L28.7441 48.0137C28.8094 47.8178 29.0864 47.8178 29.1519 48.0137Z" fill="#FFE15A"/>
    <path d="M50.9339 25.8239L50.4292 27.337L48.8342 27.3495C48.6276 27.351 48.542 27.6145 48.7081 27.7373L49.9913 28.6848L49.5103 30.2056C49.448 30.4023 49.6721 30.5654 49.8402 30.4452L51.1378 29.5176L52.4354 30.4452C52.6034 30.5653 52.8276 30.4025 52.7653 30.2056L52.2843 28.6848L53.5675 27.7373C53.7336 27.6145 53.648 27.351 53.4414 27.3495L51.8464 27.337L51.3417 25.8239C51.2764 25.628 50.9992 25.628 50.9339 25.8239Z" fill="#FFE15A"/>
    <path d="M22.7985 42.0679L22.2938 43.5811L20.6988 43.5935C20.4922 43.595 20.4067 43.8585 20.5728 43.9813L21.856 44.9289L21.3749 46.4496C21.3126 46.6463 21.5367 46.8095 21.7048 46.6893L23.0024 45.7617L24.3 46.6893C24.468 46.8093 24.6923 46.6465 24.63 46.4496L24.1489 44.9289L25.4321 43.9813C25.5982 43.8585 25.5127 43.595 25.3061 43.5935L23.711 43.5811L23.2063 42.0679C23.1409 41.872 22.8638 41.872 22.7985 42.0679Z" fill="#FFE15A"/>
    <path d="M28.7441 19.8781L28.2394 21.3912L26.6444 21.4036C26.4378 21.4052 26.3522 21.6687 26.5183 21.7915L27.8015 22.739L27.3205 24.2598C27.2582 24.4565 27.4823 24.6196 27.6504 24.4994L28.948 23.5718L30.2456 24.4994C30.4135 24.6195 30.6378 24.4567 30.5755 24.2598L30.0945 22.739L31.3777 21.7915C31.5438 21.6687 31.4582 21.4052 31.2516 21.4036L29.6566 21.3912L29.1519 19.8781C29.0864 19.6822 28.8094 19.6822 28.7441 19.8781Z" fill="#FFE15A"/>
    <path d="M44.9881 48.0137L44.4834 49.5269L42.8884 49.5393C42.6818 49.5408 42.5962 49.8043 42.7623 49.9271L44.0455 50.8747L43.5645 52.3954C43.5022 52.5921 43.7263 52.7553 43.8944 52.6351L45.192 51.7075L46.4896 52.6351C46.6576 52.7551 46.8818 52.5923 46.8195 52.3954L46.3385 50.8747L47.6217 49.9271C47.7878 49.8043 47.7022 49.5408 47.4956 49.5393L45.9006 49.5269L45.3959 48.0137C45.3305 47.8178 45.0534 47.8178 44.9881 48.0137Z" fill="#FFE15A"/>
  </svg>
);
